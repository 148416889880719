<template>
    <div class="blog-content">123</div>
</template>
  
<script>

</script>
  
<style>
.blog-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
</style>